import {
  RELATED_BLOGS_LOADER,
  RELATED_BLOGS_SUCCESS,
  RELATED_BLOGS_FAILURE,
  RELATED_BLOGS_RESET,
} from "../../constants/Blog/RelatedBlogsConstants";

const initialState = {
  isLoading: false,
  RelatedBlogs: [],
  errorMessage: "",
};

export const RelatedBlogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RELATED_BLOGS_LOADER:
      return { ...state, isLoading: true };

    case RELATED_BLOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        RelatedBlogs: action.payload,
        errorMessage: "",
      };

    case RELATED_BLOGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case RELATED_BLOGS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
