import React from "react";

const Page404 = () => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-1 fw-bold">Aw, Snap!</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span>
          </p>
          <p className="lead">
            Something went wrong while displaying this webpage.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="btn btn-primary"
          >
            Reload
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page404;
