import {
  SINGLE_BLOG_LOADER,
  SINGLE_BLOG_SUCCESS,
  SINGLE_BLOG_FAILURE,
  SINGLE_BLOG_RESET,
} from "../../constants/Blog/SingleBlogConstants";

const initialState = {
  isLoading: false,
  SingleBlog: {},
  errorMessage: "",
};

export const SingleBlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_BLOG_LOADER:
      return { ...state, isLoading: true };

    case SINGLE_BLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        SingleBlog: action.payload,
        errorMessage: "",
      };

    case SINGLE_BLOG_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case SINGLE_BLOG_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
